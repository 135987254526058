import React from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import Mapimg from "../assets/map.jpg";
import LineBreak from "./LineBreak";
import Email from "./Email";
const Contact = () => {
	const position = { lat: -90, lng: -123 };
	return (
		<div id="Contact" className="bg-primary">
			<div className="p-8 md:p-20 space-y-10 md:space-y-16 max-w-[1440px] mx-auto">
				<h1 className="text-white text-center font-montserrat font-bold text-4xl">
					Contact Us!
				</h1>
				<div className=" w-full flex flex-col lg:flex-row lg:space-x-10 ">
					<Email className={"order-2 lg:order-1 lg:w-1/2 mt-10 lg:mt-0"} />
					<div className="lg:w-1/2 h-[250px] lg:h-auto lg:order-2">
						<div className=" w-full h-full order-1">
							<iframe
								className=" w-full border-4 h-full border-red-400 rounded-3xl "
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d876.9858697068845!2d77.09576548648717!3d28.45112016711559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d196a9219452f%3A0x2b7db4e11e0c29d1!2sWeWork%20Two%20Horizon%20Centre!5e0!3m2!1sen!2sin!4v1708244707057!5m2!1sen!2sin"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
							></iframe>
						</div>
					</div>
				</div>
			</div>
			<LineBreak />
		</div>
	);
};

export default Contact;
