import React from "react";
import Service from "./Service";
import strategyconsulting from "../assets/strategy-consulting.gif";
import finacialadvisory from "../assets/financial-advisory.gif";
import dataanalytics from "../assets/data-analytics-gif.gif";
import processtransformation from "../assets/Process-transformation.gif";

const OurServices = () => {
  return (
    <div className="bg-stone-50" id="Services">
      <h4 className="text-center text-4xl font-montserrat font-semibold sm:py-10 py-8">
        At Androrithm
      </h4>
      <div>
        <section class="text-gray-600 body-font max-w-[1440px] mx-auto">
          <div class=" px-6 md:px-20 mx-auto">
            <Service
              img={strategyconsulting}
              heading="Strategy Consulting"
              text="Think of market insights as puzzle pieces. Our strategic partner helps assemble them into a clear picture of success."
              link="/service/strategyconsulting"
              order={0}
              id={"strategyConsulting"}
            />
            <Service
              id={"finacialAdvisory"}
              img={finacialadvisory}
              heading="Financial Advisory"
              text="Portfolio management stands as a cornerstone in every enduring business strategy. Be it an acquisition, internal planning, or reporting, maintaining financial precision is crucial for accurate forecasting. A dependable partner can establish robust data reporting systems and offer insights to facilitate well-informed decision-making."
              link="/service/financialadvisory"
              order={1}
            />
            <Service
              id={"processTransformation"}
              img={processtransformation}
              heading="Process Transformation"
              text="To sustain business growth plans, processes must continuously evolve. A transformation partner plays a vital role in ensuring that processes and operating models are prepared for the future, ready to support growth."
              link="/service/processtransformation"
              order={0}
            />
            <Service
              id={"dataAnalytics"}
              img={dataanalytics}
              heading="Data Analytics"
              text="Data reporting, forecasting, and visualization serve as vital components for making successful management decisions. A suitable partner can enhance accurate forecasting by utilizing software to expedite the visualization process."
              link="/service/dataanalytics"
              order={1}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurServices;
