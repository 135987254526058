import React from "react";
import LineBreak from "./LineBreak";

const Introduction = () => {
  return (
    <div id="About" className="bg-primary text-white">
      <div className="p-7 py-10 md:p-20 max-w-[1440px] mx-auto">
        <div className="space-y-8 lg:space-y-0 lg:flex items-center lg:space-x-32">
          <div className="lg:w-5/12">
            <p className=" font-montserrat text-3xl md:text-6xl font-semibold tracking-wide">
              We help global brands enter India with precise solutions.
            </p>
          </div>
          <div className="lg:w-7/12 space-y-8 bg-white bg-opacity-10 p-6 md:p-10 rounded-3xl ">
            <div className="space-y-2 md:space-y-4">
              <h4 className="text-xl md:text-2xl font-bold">What we do</h4>
              <p className="font-lora tracking-wider text-sm md:text-lg">
                At Androrithm, we thrive on fostering connections between global
                enterprises and India's vibrant growth landscape. Our strategic
                vision and cutting-edge technologies facilitate symbiotic
                partnerships, propelling cross-border sustainable prosperity
              </p>
            </div>
            <div className="space-y-2 md:space-y-4">
              <h4 className="text-xl md:text-2xl font-bold">What we are</h4>
              <p className="font-lora tracking-wider text-sm md:text-lg">
                Androrithm’s core mission is to facilitate seamless business
                expansion in India. Through personalized consultancy,
                cutting-edge technology, and strategic collaborations, we equip
                organizations to thrive. Our collective efforts drive growth,
                foster innovation, and pave the way for unparalleled success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <LineBreak id="introLinebreak" />
    </div>
  );
};

export default Introduction;
