import React from "react";
import LinkedinLogo from "../assets/icons/linkedin.png";
import Emaillogo from "../assets/icons/email.png";
import Instalogo from "../assets/icons/instagram.png";
import Facebooklogo from "../assets/icons/facebook.png";
import Xlogo from "../assets/icons/x.png";

const NewFooter = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-secondary flex items-center h-[300px] md:h-[400px] lg:h-[475px]  w-full">
        <div className="max-w-[1440px] mx-auto w-full p-10 md:p-14 lg:p-20 flex items-center justify-between">
          <div className="flex flex-col space-y-4 ">
            <h1 className="text-4xl md:text-6xl text-white font-montserrat font-semibold">
              Let Us Help You
            </h1>

            <span className="flex items-center text-white text-xl md:text-3xl">
              Reach Us &nbsp; &#x2192;
            </span>
          </div>
        </div>
      </div>
      <div
        className="bg-gray-700 w-10/12 max-w-[900px] -translate-y-[70px] 
      flex flex-col items-center lg:items-start p-20 lg:pb-0 text-white lg:h-[300px] lg:justify-between"
      >
        <div className="mb-14 text-center capitalize text-xl lg:text-2xl lg:flex lg:space-x-10 lg:font-medium">
          <a className="hover:text-red-400 duration-300" href="#About">
            Who We Are
          </a>
          <a className="hover:text-red-400 duration-300" href="#Contact">
            How Can We Help
          </a>
          <a className="hover:text-red-400 duration-300" href="#introLinebreak">
            Resource Center
          </a>
        </div>
        <div className="lg:w-full lg:flex justify-between">
          <div className="flex  items-center space-x-6 lg:space-x-4 shrink-0 mb-5 ">
            <a
              href="https://www.linkedin.com/company/androrithm-private-limited/"
              target="_blank"
              className="hover:scale-125 duration-150"
            >
              <img src={LinkedinLogo} alt="" className="w-6 md:w-7" />
            </a>
            <a href="" className="hover:scale-125 duration-150">
              <img src={Emaillogo} alt="" className="w-6 md:w-7" />
            </a>
            <a
              href=""
              className="hover:scale-125 duration-150 bg-white rounded-md"
            >
              <img src={Xlogo} alt="" className="w-6 md:w-7" />
            </a>
            <a href="" className="hover:scale-125 duration-150">
              <img src={Facebooklogo} alt="" className="w-6 md:w-7" />
            </a>
            <a href="" className="hover:scale-125 duration-150">
              <img src={Instalogo} alt="" className="w-6 md:w-7" />
            </a>
          </div>
          <div className="text-base text-center lg:text-left">
            <h1>@2024 Androrithm</h1>
            {/* <h3>Created by Kaustubh</h3> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
