import React from "react";
import { Link } from "react-router-dom";

const ServiceLink = ({className, text, to, color, border}) => {
	return (
		<a href={to} className={`${className} ${color} ${border}  flex-1 flex items-center justify-center h-8 hover:h-10 hover:rounded-t-xl duration-300 border-0 text-xs`}>
			{text}
		</a>
	);
};

export default ServiceLink;
