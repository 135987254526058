import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const Email = ({ className }) => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
	});

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		emailjs
			.send(
				process.env.REACT_APP_SERVICE_ID,
				process.env.REACT_APP_TEMPLATE_ID,
				formData,
				{
					publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
				}
			)
			.then((res) => {
				toast.success("Message Sent!", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setFormData({ name: "", email: "", subject: "", message: "" });
			})
			.catch((err) => {
				toast.error("Please try again!", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
			});
	};

	return (
		<form className={`${className} space-y-3`} onSubmit={handleSubmit}>
			<div className="flex space-x-3">
				<div className="w-full rounded-lg border-4 border-transparent has-[:focus]:border-red-400 leading-relaxed h-10 p-5 flex items-center bg-white">
					<img src="" alt="" />
					<input
						type="text"
						name="name"
						value={formData.name}
						onChange={handleChange}
						className="w-full outline-none "
						placeholder="Your Name"
					/>
				</div>
				<div className="w-full rounded-lg border-4 border-transparent has-[:focus]:border-red-400 leading-relaxed h-10 p-5 flex items-center bg-white">
					<img src="" alt="" />
					<input
						type="email"
						name="email"
						value={formData.email}
						onChange={handleChange}
						className="w-full outline-none "
						placeholder="Email"
					/>
				</div>
			</div>

			<div className="w-full rounded-lg border-4 border-transparent has-[:focus]:border-red-400 leading-relaxed h-10 p-5 flex items-center bg-white">
				<img src="" alt="" />
				<input
					type="text"
					name="subject"
					value={formData.subject}
					onChange={handleChange}
					className="w-full outline-none "
					placeholder="Subject"
				/>
			</div>

			<div className="w-full rounded-lg border-4 border-transparent has-[:focus]:border-red-400 leading-relaxed p-4 flex items-center bg-white">
				<textarea
					name="message"
					value={formData.message}
					onChange={handleChange}
					placeholder="Enter your message here . . . "
					className="w-full group-focus:border-4 focus:border-red-400 rounded-lg outline-none h-[170px] lg:h-[250px]"
				></textarea>
			</div>
			<button type="submit" className="text-lg border-2 border-red-400 bg-transparent hover:bg-red-400 duration-300 px-3 py-1 rounded-lg text-white">Submit</button>
		</form>
	);
};

export default Email;
