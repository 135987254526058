import React from "react";
import buildings from "../assets/introbg.jpeg";
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";
import ServiceLink from "./ServiceLink";

const Home = () => {
  return (
    <div
      id="Home"
      className="h-screen w-full relative flex flex-col items-center justify-center"
    >
      <img
        src={buildings}
        alt=""
        className="w-full h-full absolute top-0 left-0 brightness-50 object-cover"
      />
      {/* <div className="text-white w-full max-w-[1440px] px-10 lg:px-16 space-y-8 mt-20 z-10">
        <h1 className="text-5xl  md:text-7xl font-spartan font-semibold align-center">ANDRORITHM </h1>
        <h4 className="font-semibold text-sm md:text-base">
          <Typewriter
            options={{
              strings: [
                "Embrace Intelligent Solutions",  
                "Unlocking Potential through Innovative Solutions",
                "Tailored Solutions, Trusted Consultancy",
              ],
              autoStart: true,
              loop: true,
              delay: 50,
            }}
          />
        </h4>
      </div> */}
      <div className="text-white w-full max-w-[1440px] px-10 lg:px-16 space-y-8 mt-20 z-10 text-center">
        <h1 className="text-5xl md:text-7xl font-semibold">ANDRORITHM</h1>
        <h4 className="font-semibold text-sm md:text-base">
          <Typewriter
            options={{
              strings: [
                "Embrace Intelligent Solutions",
                "Unlocking Potential through Innovative Solutions",
                "Tailored Solutions, Trusted Consultancy",
              ],
              autoStart: true,
              loop: true,
              delay: 50,
            }}
          />
        </h4>
      </div>

      <div className="w-full absolute bottom-0 left-0">
        <div className="max-w-[1440px] w-full mx-auto   text-white  flex font-montserrat items-end">
          <ServiceLink
            text="Strategy Consulting"
            to="#introLinebreak"
            color="bg-secondary"
            border="border border-black"
          />
          <ServiceLink
            text="Financial Advisory"
            to="#strategyConsulting"
            color="bg-white text-black"
          />
          <ServiceLink
            text="Process Transformation"
            to="#finacialAdvisory"
            // color="bg-blue-300"
            color="bg-secondary"
          />
          <ServiceLink
            text="Data Analytics"
            to="#processTransformation"
            // color="bg-[#80859f]"
            color="bg-white text-black"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
