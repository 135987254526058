import React from "react";
import Header from "../components/Header";
import Home from "../components/Home";
import Introduction from "../components/Introduction";

import Footer from "../components/Footer";
import { ToastContainer } from "react-toastify";
import OurServices from "../components/OurServices";
import NewFooter from "../components/NewFooter";
import Contact from "../components/Contact";

const HomePage = () => {
	return (
		<div className="font-montserrat">
			<Header />
			<Home />
			<Introduction />
			{/* <About /> */}
			{/* <Features /> */}
			<OurServices />
			{/* <Team /> */}
			<Contact/>
			{/* <Footer /> */}
			<NewFooter />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</div>
	);
};

export default HomePage;
