import React, { useEffect, useState } from "react";
import Header from "../../components/Header";

import ServiceLink from "../../components/ServiceLink";
import Dropdown from "../../components/Dropdown";
import Footer from "../../components/Footer";

const defaultData = {
  "Growth Strategy": {
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, numquam, optio libero consectetur expedita nisi veritatis tempora corrupti iure asperiores minima aspernatur ratione dicta? Impedit.",
    points: {
      "Go To market Strategy": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
      "Go To market": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
      "Go To marke": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
    },
  },
  "Business Strategy": {
    text: "asdfasdfasdfasdfasdfasdfatur ratione dicta? Impedit.",
    points: {
      "Go To market Strategy": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
      "Go To market Strategy asd": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
      "Go To market Strategy as": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
    },
  },
  "Economics Consulting": {
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, numquam, optio libero consectetur expedita nisi veritatis tempora corrupti iure asperiores minima aspernatur ratione dicta? Impedit.",
    points: {
      "Go To market Strategy": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
      "Go To market Strategy asd": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
      "Go To market Strategy as": [
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
        "Designing aksdfnlas f ansdifnlas dflka ns flasd fjlasdf lasd f",
      ],
    },
  },
};

const Service = ({
  img,
  heading,
  headingSubtext,
  overview,
  summary,
  data = defaultData,
}) => {
  const [mounted, setMounted] = useState(false);

  const [subtopicSelected, setSubtopicSelected] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 300);
  }, []);

  return (
    <div className="overflow-hidden  ">
      <Header />

      {/* Home */}
      <div className="h-screen w-full relative flex justify-center ">
        <img
          src={img}
          alt=""
          className={`absolute top-0 left-0 w-full h-full object-cover duration-500  ${
            !mounted ? "translate-x-full" : "translate-x-0"
          }`}
        />
        <div className=" h-full bg-black bg-opacity-80 z-20 flex-1"></div>
        <div className="max-w-[1440px] w-full h-full mx-auto relative ">
          <div
            className={` 
					absolute left-0 top-0 w-[420px] bg-black bg-opacity-95 h-full flex flex-col justify-center text-white py-20 px-7 space-y-20 duration-300 ${
            !mounted ? "-translate-x-full" : "translate-x-0"
          }`}
          >
            <h1 className="text-5xl font-montserrat leading-[1.2] ">
              {heading}
            </h1>

            <h6 className="text-2xl font-extralight font-montserrat leading-relaxed tracking-wide ">
              {headingSubtext}
            </h6>
          </div>
        </div>
        <div className=" h-full bg-black bg-opacity-80 z-20 flex-1"></div>
      </div>

      <div className="text-center p-10 max-w-[1440px] mx-auto">
        {summary.map((para, index) => (
          <>
            {para}
            {index !== summary.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}
          </>
        ))}
      </div>

      <div className="w-full ">
        <div className="max-w-[1440px] w-full mx-auto   text-white  flex font-montserrat items-end px-10">
          {Object.keys(data).map((subTopic, index) => {
            return (
              <button
                onClick={() => {
                  setSubtopicSelected(index);
                }}
                className={`flex-1 flex items-center justify-center h-10  duration-300 border-0 text-sm font-semibold  
								${subtopicSelected === index ? "bg-primary" : "bg-secondary"} 
								`}
              >
                {subTopic}
              </button>
            );
          })}
        </div>
      </div>

      <div className="flex p-5">
        {Object.keys(data).map((subTopic, index) => {
          return (
            <div
              className={` 
							  w-full max-w-[1440px] mx-auto p-5 md:p-10 md:space-x-10 justify-between 
								space-y-10 md:space-y-0

								min-h-[430px]
							${subtopicSelected === index ? " block md:flex" : "hidden"}`}
            >
              <h1 className="text-justify">
                {data[subTopic].text.map((para, index) => (
                  <>
                    {para}
                    {index !== data[subTopic].text.length - 1 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </>
                ))}
              </h1>

              <Dropdown
                className={"md:min-w-[300px] lg:min-w-[500px]"}
                data={data[subTopic].points}
              />
            </div>
          );
        })}
      </div>

      <Footer />
    </div>
  );
};

export default Service;
