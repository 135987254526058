import React from "react";
import Service from "./Service";
import buildings from "../../assets/buildings2.jpg";
import financialAdvisoryBg from "../../assets/finadv.jpg";
import proccessTransformationBg from "../../assets/Process-transformation-bg.jpg";
import dataAnalyticsBg from "../../assets/data-analytics-bg.jpg";
import { Route, Routes } from "react-router-dom";

const Services = () => {
  return (
    <Routes>
      <Route
        path="/strategyconsulting"
        element={
          <Service
            img={buildings}
            heading="Strategy Consulting"
            headingSubtext="Your ultimate guide to winning strategies"
            summary={[
              "In today's dynamic business landscape, strategic planning is paramount for sustained success. Our methodology serves as a guiding star, navigating technological shifts and emerging methods. With our adept team, we translate your vision into actionable plans, ensuring continued growth and profitability. We're committed to providing adaptable solutions for long-term success, partnering with you to navigate the complexities of modern business with confidence and clarity.",
            ]}
            data={{
              "Growth Strategy": {
                text: [
                  "In the dynamic landscape of today’s business world, organizations must maintain a keen emphasis on growth to stay relevant. The implementation of a growth strategy, adaptable across various sectors, is a vital component in business operations and can often be intricate. Strengthening operations and enhancing efficiencies to stimulate organic growth are as essential as the ability to identify potential acquisitions to accelerate the growth path.",
                  " Our team of consultants is equipped to pinpoint new markets for your business expansion and aid in the formulation and execution of strategies that are backed by thorough research and focused on delivering results.",
                ],
                points: {
                  "Go To market Strategy": [
                    " Crafting a comprehensive go-to-market strategy for your offerings aligned with market dynamics and competitor case studies.",
                    "Precision-targeting the appropriate audience and crafting tailored messaging for your products and services.",
                    "Enhancing brand presence in target markets and specific segments to bolster market positioning.",
                  ],
                  "Market Sizing": [
                    "Evaluate the overall market size and revenue trajectory, considering historical data and projected growth.",
                    "Determine the precise size of the addressable market for your business, both presently and in future periods.",
                    "Conduct a comprehensive market analysis encompassing growth catalysts, obstacles, competitive landscape, and emerging trends.",
                  ],
                  "Country Attractiveness": [
                    "Evaluating nations based on macroeconomic and socio-political factors, regulatory environments, economic obstacles, and business-friendly conditions.",
                    "Examining operational markets including local supply chains, workforce availability, consumer trends, and competitive landscape.",
                    "Developing comprehensive country scorecards and assisting in formulating growth strategies at the national level.",
                  ],
                },
              },
              "Business Strategy": {
                text: [
                  "Understanding the path forward is essential for a prosperous journey. Often, organizations become consumed by their operational demands, leaving little room for strategic planning. Regular strategic evaluations, particularly those focused on long-term planning, are crucial to ensure alignment with the broader business vision and goals. Moreover, acquiring pertinent industry knowledge and assessing market competition has become increasingly important due to the ever-changing business landscape, shifting industry dynamics, and the growing complexity of business models.",
                  "Our comprehensive analysis of your internal strengths, the external business environment, and your unique value proposition for each market serves as the driving force behind the formulation of an effective strategy for your business.",
                ],
                points: {
                  "Long Term Strategy Planning": [
                    "Evaluate internal competencies and align them with external factors and opportunities.",
                    "Examine unit and corporate performance to formulate detailed strategies.",
                    "Create a comprehensive 5-year strategic planning process encompassing both unit and corporate strategies.",
                  ],
                  "Industry Assessment": [
                    "Conducting an in-depth analysis of the industry landscape, including market structure, trend evaluation, capability gaps, and market size determination.",
                    "Evaluating competition and benchmarking business performance against industry standards.",
                    "Crafting a tailored strategy to penetrate the market effectively, ensuring sustained and profitable growth.",
                  ],
                  "Competitive Landscape": [
                    "Conduct thorough competitor analyses encompassing financial performance, strengths, weaknesses, and market share dynamics.",
                    "Employ purposeful profiling techniques to uncover intricate aspects of competitor operations.",
                    "Integrate competitive intelligence seamlessly into strategic planning to formulate a focused and results-driven business strategy.",
                  ],
                },
              },
              "Public Procurement": {
                text: [
                  "Access a comprehensive range of  services  for onboarding on Digital Public Procurement Platforms such as registration, brand approval, profile completion, product upload, order processing, incident management, service upload and tender bidding.",
                ],
                points: {
                  "Gem Registration": [
                    "GEM Profile Completion as 100%",
                    "GEM Caution mone account creation and guidance",
                    "OEM Vendor Assessment process guidance",
                    "Reseller profit management and guidance",
                    "Products catalog listing and guidance",
                    "Service catalog and guidance",
                  ],
                  "Catalog management service": [
                    "Bidding /RA participation of live bids",
                    "BID clarification guidance",
                    "Order acceptance process guidance",
                    "Invoice generation process guidance",
                  ],
                  "Profile management service": [
                    "Online technical support through remote connection mobile and email",
                  ],
                },
              },
            }}
          />
        }
      ></Route>
      <Route
        path="/financialadvisory"
        element={
          <Service
            img={financialAdvisoryBg}
            heading="Financial Advisory"
            headingSubtext="Empowering financial growth with tailored solutions"
            summary={[
              "In today's dynamic business landscape, strategic planning is paramount for sustained success. Our methodology serves as a guiding star, navigating technological shifts and emerging methods. With our adept team, we translate your vision into actionable plans, ensuring continued growth and profitability. We're committed to providing adaptable solutions for long-term success, partnering with you to navigate the complexities of modern business with confidence and clarity.",
              "The prudent allocation of scarce capital is pivotal, whether it's directed towards fueling growth initiatives or returning value to shareholders through dividends. Consequently, the ability to make agile and well-informed decisions regarding where, how, and when to invest assumes paramount importance.",
            ]}
            data={{
              "Investment Advisory": {
                text: [
                  "At Androrithm, we specialize in personalized financial advisory services. We help families protect their wealth, transfer it across generations, optimize taxes, and manage risks effectively. Our approach involves working closely with each family to understand their unique goals and create tailored strategies. We focus on diversifying investments, planning estates, and safeguarding assets comprehensively. With regular reviews and flexible plans, we ensure ongoing financial security and legacy preservation. Our team provides individualized attention and strategic solutions to improve the financial well-being of every family we serve.",
                ],
                points: {
                  "Investment Management": [
                    "Portfolio Management.",
                    "Strategic Asset Allocation ",
                    "Budget and Management",
                  ],
                  Advisory: [
                    "Risk Management",
                    "Tax and Legal Advisor",
                    "Compliance and Regulatory Assistance",
                    "Liability Management.",
                    "Loans",
                  ],
                  Insurances: [
                    "Fire",
                    "Marine",
                    "Jewellery",
                    "GMC",
                    "GPA",
                    "Workman Compensation",
                    "Motor",
                    "Health and Travel",
                  ],
                },
              },
              "Financial Planning and Analysis": {
                text: [
                  "In today's dynamic global corporate landscape, the importance of robust financial planning and cash flow management cannot be overstated. A forward-thinking financial strategy, seamlessly integrated with annual budgets and the overarching strategic business plan, is indispensable for organizational success.",
                  "Our team of Financial Advisory consultants specializes in crafting sophisticated Financial Planning & Analysis (FP&A) frameworks, serving as the financial backbone of your enterprise. This framework ensures alignment between financial objectives and operational realities, fostering both stability and growth. Collaborating closely with your teams, our experts develop comprehensive multi-year financial models and facilitate annual target-setting processes, empowering you to assert full control over your financial management function. Our emphasis lies on creating adaptable models that provide actionable insights into areas deviating from planned trajectories.",
                  "Trust us to empower your organization with the financial agility and foresight needed to navigate today's complex business landscape.",
                ],
                points: {
                  "Budgeting and forecasting": [
                    "Formulating business unit budgets from the ground up, culminating in consolidation at divisional and group levels.",
                    "Establishing a thorough overview of revenue and cost breakdowns for managerial insight.",
                    "Utilizing FP&A frameworks to align operational metrics with strategic goals and generate precise financial projections.",
                  ],
                  "Cost benchmarking": [
                    "Formulating business unit budgets from the ground up, culminating in consolidation at divisional and group levels.",
                    "Establishing a thorough overview of revenue and cost breakdowns for managerial insight.",
                    "Utilizing FP&A frameworks to align operational metrics with strategic goals and generate precise financial projections.",
                  ],
                  "Dashboard and reporting": [
                    "Designing streamlined, automated dashboards for clear decision-making.",
                    "Comprehensive reporting solutions from setup to report design and development.",
                    "Simulating diverse material variables to assess their financial impact across multiple scenarios.",
                  ],
                  "Board Packs": [
                    "Develop, maintain, and enhance management reports and board packs to assess business performance.",
                    "Prepare performance packs for senior management, providing comprehensive insights to support timely decision-making.",
                  ],
                },
              },
              "Equity Research": {
                text: [
                  "Equity Research serves as the cornerstone of operations for full-service investment banks. These teams are tasked with providing valuable insights into the sectors and companies they cover, offering comprehensive analyses of market dynamics and financial performance.",
                  "At Androrithm, we specialize in delivering a range of services to support equity research efforts. From crafting and maintaining intricate financial models and industry databases to producing detailed earnings reviews and initiating coverage reports, we ensure our clients receive thorough and insightful analyses tailored to their needs.",
                ],
                points: {
                  "Portfolio Analysis": [
                    "At our consultancy firm, we dive deep into your existing portfolio to understand how it's performing, what risks it faces, and whether it aligns with your financial goals.",
                  ],
                  "Risk Assement": [
                    "We dive deep into your portfolio to understand and measure the risks involved, guiding you in making smart choices.",
                  ],
                  "Asset Allocation": [
                    "Achieve a balanced and diversified portfolio customized to your goals and risk tolerance. Our strategies are designed to optimize returns without compromising your comfort level.",
                  ],
                  "Investment Selection": [
                    "Our research team identifies and evaluates investment opportunities across various asset classes, including equities, bonds, and alternative investments.",
                  ],
                  "Performance Tracking": [
                    "Keep an eye on how your portfolio is performing with our easy-to-use tracking tools and reports. This way, you can adjust your strategy as needed.",
                  ],
                  "Economic Forecast": [
                    "Our analysis keeps you updated on the big picture, highlighting macroeconomic factors that could impact your investments, so you can navigate the financial seas with confidence.",
                  ],
                },
              },
            }}
          />
        }
      ></Route>
      <Route
        path="/processtransformation"
        element={
          <Service
            img={proccessTransformationBg}
            heading="Process Transformation"
            headingSubtext="Process metamorphosis: making consultancy a breeze"
            summary={[
              "In today's fast-paced business environment, 'Transformation' is a term frequently used but often interpreted differently by various organizations. It serves as a vital tool for meeting the evolving needs of stakeholders amidst market disruptions. Our approach to transformation focuses on integrating processes, people, delivery, and technology to drive value creation in line with corporate strategies and industry standards.",
            ]}
            data={{
              "Target Operating Model Design": {
                text: [
                  "Our vision for a futuristic Target Operating Model (TOM) revolves around elevating businesses to new heights, thereby unlocking their inherent value. Central to this endeavour is a thorough comprehension of the existing Operating Model and the implementation of a transformative industry framework tailored precisely to meet our client's unique requirements, driving the adoption of 'Best-for-fit' models as our primary focus.",
                  "Embedded within our TOM design is a meticulous consideration of the future growth trajectories of these businesses, encompassing how they serve their end customers and manage internal processes. This design ethos resonates with the overarching strategic objectives of our clients' corporations. Our approach affords management a comprehensive and easily digestible view of the future state, facilitating visualization and measurement through robust business cases.",
                  "At every step of the journey, we stand shoulder-to-shoulder with our clients, guiding them from the 'as-is' to the 'to-be' state, ensuring the realization of committed benefits. Additionally, we take charge of identifying and evaluating the most suitable technologies and business process outsourcing partners, guaranteeing the delivery of promised productivity and financial gains.",
                  "In essence, our methodology embodies a blend of strategic foresight, collaborative partnership, and meticulous execution, aimed at propelling businesses towards sustainable growth and success in an ever-evolving landscape.",
                ],
                points: {
                  "Process Re-engineering": [
                    "Utilizing industry expertise, data analysis techniques, and technology integration to craft optimized future processes.",
                    "Proactively addressing main points through phased implementation to mitigate risks before full deployment.",
                  ],
                  Technology: [
                    "Utilizing technology as the foundation of the Target Operating Model (TOM) for comprehensive ecosystem design.",
                    "Facilitating seamless integration and resource optimization within the ecosystem, ensuring full adoption for tangible benefits.",
                  ],
                  "Center for Excellence": [
                    "Spearheading the implementation of optimal sourcing strategies across diverse business contexts.",
                    "Crafting thorough analyses to guide decisions between in-house operations and outsourcing, encompassing strategic considerations.",
                    "Designing end-to-end solutions to streamline, unify, and formalize processes within shared service frameworks.",
                  ],
                  "Process optimization": [
                    "Optimizing processes serves as a crucial means to achieving organizational goals, rather than an end in itself. Often, companies encounter challenges in adapting or refining their processes to align with dynamic market conditions. This is primarily because their focus tends to be on top-line growth initiatives such as market expansion or acquisitions, leaving little emphasis on revising outdated processes. While some legacy processes may not necessitate a complete overhaul, they can benefit from improvements or strategic change management interventions. Our team of experts is dedicated to swiftly identifying viable solutions to drive transformational initiatives effectively.",
                  ],
                },
              },
              "Process Standardization and Consolidation": {
                text: [
                  "We specialize in crafting tailored processes that prioritize consistency and consolidation, fostering seamless integration and operational efficiency. Our approach ensures that process flows are easily understandable, thereby facilitating successful implementation and adoption across the organization.",
                ],
                points: {
                  "Documentation and training": [
                    "Establishing documentation trails to enhance governance after transformation.",
                    "Creating, organizing, and updating process maps, SOPs, and workflows using KM tools.",
                    "Promoting accessibility and conducting training to encourage adopting and integrating new processes.",
                  ],
                  "System integration and automation": [
                    "Assessing RPA, AI, ML, etc., opportunities in legacy system due diligence.",
                    "Integrating identified automation components into technology ecosystem design and implementation.",
                  ],
                },
              },
              "Programme Management": {
                text: [
                  "Effective programme or project management serves as the cornerstone of any successful endeavour. Often, the downfall of transformation initiatives can be traced back to inadequate communication strategies and a lack of buy-in from the organizational culture.",
                  "Our approach involves close collaboration with management to craft and implement a robust communication strategy that fosters cultural acceptance of these programmes. We meticulously design programmes that align with our client's objectives, ensuring seamless dissemination throughout all levels of the team and pertinent stakeholders.",
                  "Our adept Project Managers uphold the integrity of governance structures, ensuring compliance and validation, while also ensuring that expectations and deliverables are articulated and communicated at every stage. Leveraging our team's proficiency in various PMO tools, we offer lucidity and generate valuable insights to drive success.",
                ],
                points: {
                  "Directing Multiple Channels": [
                    "Orchestrating concurrent operational and financial processes to drive transformation.",
                    "Monitoring project performance and adhering to budgetary plans.",
                    "Steering projects and programs towards their intended objectives, ensuring successful completion.",
                  ],
                  "Communication strategy": [
                    "Formulating a comprehensive communication strategy integrating organizational and regional cultural nuances.",
                    "Establishing tailored communication channels for key stakeholders (management, Board, employees, and clients) to enhance engagement and participation.",
                  ],
                  "Governance & reporting": [
                    "Establishing robust governance and reporting structures for clarity and transparency.",
                    "Utilizing analytics to monitor program efficacy and deliver reports to project sponsors.",
                    "Proactively addressing deviations and variances to ensure prompt corrective measures.",
                  ],
                },
              },
            }}
          />
        }
      ></Route>
      <Route
        path="/dataanalytics"
        element={
          <Service
            img={dataAnalyticsBg}
            heading="Data Analytics"
            headingSubtext="Your ultimate guide to winning strategies"
            summary={[
              "The maxim 'Data is the new Currency' underscores the significance of the vast data landscape in today's corporate realm. However, the real challenge lies in transforming this abundance of data into actionable insights that drive organizational progress. As technologies like cloud computing, IoT, and AI advance, they exponentially increase companies' data volumes, demanding strategic utilization for sustained growth.",
              "In this context, Data Analytics emerges as more than just number manipulation or visualization; it becomes a vital process for organizing chaos. Our Data Consultants collaborate closely with client teams to establish robust reporting and analysis systems, facilitating informed decision-making through a consistent flow of meaningful data.",
            ]}
            data={{
              "Descriptive, Predictive and Prescriptive Analytics ": {
                text: [
                  "For businesses striving to gain a larger market share, organizational data represents a valuable asset akin to a goldmine. Whether it's understanding end customers' buying patterns or developing predictive maintenance plans for clients' facilities and assets, we utilize a wide array of analytics methods tailored specifically to address each business challenge, ranging from descriptive to prescriptive approaches.",
                  " Our Data Consultants go through the data to give it structure, design objective-oriented processes, and work in tandem with client teams to cover crucial service areas.",
                ],
                points: {
                  "Root Cause Analysis": [
                    " Identifying root causes of problems or expected outcomes to systematically determine the right approach towards a solution",
                    "Leveraging ‘Causal Forecasting’ models to establish the relationship between input and the probabilities of output",
                  ],
                  "Actionable Insights for Decision Support": [
                    "Creating valuable insights by running statistical models on unstructured data",
                    "Converting volumes of data to derive meaningful insights for the management",
                  ],
                },
              },
              "Data Extraction and Modelling": {
                text: [
                  "Garbage in is Garbage Out - We understand this very well and hence prefer to begin at the most granular of levels. We believe early cleaning, standardization, and calibration of data (or sources) can obviate several structural issues that tend to creep up later in the analysis. Many companies treat this as low-end service, however, we believe in correcting the source or input data for our clients to smoothen the further processing.",
                ],
                points: {
                  "Data Cleaning and Analysis": [
                    "Performing data sanity checks and triangulation from your systems to identify and fix gaps",
                    "Using statistical tools to draw out tailored and meaningful analysis from collected data",
                  ],
                  "Applied forecasting models": [
                    "We apply a blend of industry wide used forecasting models to present multiple scenarios, and test hypothesis. Our consultants use models that are fit to use basis your requirements.",
                    "Applying a blend of forecasting models used industrywide to present multiple scenarios and test hypothesis",
                    "Selecting the best-fit models for your specific requirements",
                  ],
                  "Performance Tracking using BI": [
                    "Designing and implementing relevant performance metrics to facilitate effective and easy tracking",
                    "Using BI tools to generate dashboards and other reports as well as highlight ‘grey areas’ that merit corrective actions",
                  ],
                },
              },
              "Reporting and Visualization": {
                text: [
                  "Effective reporting is necessitated by a multitude of reasons, including:",
                  "Multiple reports created in silos, which lack linkages and continuity.",
                  "Too many reports, often capturing overlapping data.",
                  "The need for visualization stems from the requirement to provide an easy way to see and understand trends, outliers, and patterns in data.",
                  "We take care of the end-to-end reporting needs of our clients - whether financial or non-financial. Our offerings help clear the haze and establish clear reporting structures.",
                ],
                points: {
                  "Business Analysis and Reporting": [
                    "Coordinating with client’s teams, performing analysis, and building reports customized for the end audience",
                    "Designing and building tailored analysis basis business dynamics, generating insights for the management and business heads",
                  ],
                  "Reports Rationalizing and Automation ": [
                    "Developing optimization programmes to drive rationalization and consolidation of quality output",
                    "Leveraging standard industry tools and solutions to automate and manage reports",
                  ],
                  "Dynamic Dashboards Using BI Tools": [
                    "Designing customized dashboards for real-time reporting across multiple scenarios",
                    "Generating real-time insights on client systems to save time in building dashboards",
                  ],
                },
              },
            }}
          />
        }
      ></Route>
    </Routes>
  );
};

export default Services;
