import React from "react";
import Logo from "../assets/FullLogoWhite.png";
import LinkedinLogo from "../assets/icons/linkedin.png";
import second from "../assets/icons/email.png";
import Emaillogo from "../assets/icons/email.png";
import Instalogo from "../assets/icons/instagram.png";
import Facebooklogo from "../assets/icons/facebook.png";
import Xlogo from "../assets/icons/x.png";
import AWhite from "../assets/AWhite.png";

const Footer = () => {
  return (
    <div className="bg-secondary">
      <div className="p-6 lg:px-20 lg:py-10 items-center justify-between text-sm max-w-[1440px] mx-auto">
        <div className="flex justify-between items-center  pb-6">
          <div>
            <img src={AWhite} alt="" className="lg:hidden w-[70px]" />
            <img
              src={Logo}
              alt=""
              className="w-[250px] object-cover hidden lg:block"
            />
          </div>
          <p className="text-white  hidden lg:block">
            @2024 Androrithm Private Ltd.
          </p>
          <div className="flex items-center space-x-6 lg:space-x-4 shrink-0">
            <a
              href="https://www.linkedin.com/company/androrithm-private-limited/"
              target="_blank"
              className="hover:scale-125 duration-150"
            >
              <img src={LinkedinLogo} alt="" className="w-6 md:w-7" />
            </a>
            <a href="" className="hover:scale-125 duration-150">
              <img src={Emaillogo} alt="" className="w-6 md:w-7" />
            </a>
            <a href="" className="hover:scale-125 duration-150">
              <img src={Xlogo} alt="" className="w-6 md:w-7" />
            </a>
            <a href="" className="hover:scale-125 duration-150">
              <img src={Facebooklogo} alt="" className="w-6 md:w-7" />
            </a>
            <a href="" className="hover:scale-125 duration-150">
              <img src={Instalogo} alt="" className="w-6 md:w-7" />
            </a>
          </div>
        </div>
        <hr />
        <div className=" items-center ">
          <p className="italic text-white py-4 lg:hidden">
            @2021 Androrithm Private Ltd.
          </p>

          <ul className="flex flex-wrap text-white  whitespace-nowrap">
            <li className="hover:text-red-400 duration-300 mr-5 mt-3 lg:mt-5 lg:text-base sm:mr-8 md:mr-10">
              <a href="">Privacy Policy</a>
            </li>
            <li className="hover:text-red-400 duration-300 mr-5 mt-3 lg:mt-5 lg:text-base sm:mr-8 md:mr-10">
              <a href="">Disclaimer</a>
            </li>
            <li className="hover:text-red-400 duration-300 mr-5 mt-3 lg:mt-5 lg:text-base sm:mr-8 md:mr-10">
              <a href="">Security Policy</a>
            </li>
            <li className="hover:text-red-400 duration-300 mr-5 mt-3 lg:mt-5 lg:text-base sm:mr-8 md:mr-10">
              <a href="">Cookies</a>
            </li>
            <li className="hover:text-red-400 duration-300 mr-5 mt-3 lg:mt-5 lg:text-base sm:mr-8 md:mr-10 ">
              <a href="">Terms and Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
