import React, { useState } from "react";

const Dropdown = ({ className, data }) => {
	const [menuOpen, setMenuOpen] = useState(-1);

	return (
		<div className={`${className} md:w-min`}>
			{Object.keys(data).map((Heading, index) => {
				return (
					<div className="">
						<div
							className={`text-white p-5 w-full duration-200 flex items-center justify-between text-nowrap flex-shrink-0 space-x-10 ${
								menuOpen !== index ? "bg-primary" : "bg-secondary"
							}`}
							onClick={() => {
								if (menuOpen === index) setMenuOpen(-1);
								else setMenuOpen(index);
							}}
						>
							<p className="uppercase m-0">{Heading}</p>
							<svg
								className="w-6 flex-shrink-0 ml-0"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								class="w-6 h-6"
							>
								<path
									fill-rule="evenodd"
									d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
						<ul
							className={`list-disc duration-700 overflow-hidden pl-7  ease-in-out  ${
								menuOpen === index ? "max-h-[600px] " : "max-h-0"
							} `}
						>
							<br />
							{data[Heading].map((textLine) => {
								return <li className="mb-4">{textLine}</li>;
							})}
							<br />
						</ul>
					</div>
				);
			})}
		</div>
	);
};

export default Dropdown;
