import React from "react";
import { Link } from "react-router-dom";

const Service = ({ className, heading, img, text, link, order, id }) => {
  return (
    <div className={`${className} flex `}>
      <div class="flex items-center  mx-auto border-b pb-10 mb-10 border-gray-400 sm:flex-row flex-col">
        <img
          className={`md:h-72 sm:h-40 h-32 aspect-square inline-flex items-center justify-center rounded-full flex-shrink-0 object-cover ${
            order == 0 ? "sm:order-first sm:mr-10" : "sm:order-1 sm:ml-10"
          }`}
          src={img}
          alt="image
        "
        />
        <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
          <h2 class="text-gray-900 text-lg md:text-2xl title-font font-semibold mb-2 tracking-wider">
            {heading}
          </h2>
          <p class="leading-relaxed text-base">{text}</p>
          <Link
            id={id}
            to={link}
            class="mt-3 inline-flex items-center bg-secondary text-white px-4 py-2 rounded-lg  duration-300"
          >
            Learn More
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Service;
